<!DOCTYPE html>
<html>

<head>
  <title>Inkopen en verkopen</title>
  <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no">

</head>

<body>
  <div class="container text-center div-style" >
    <h2 class="text-secondary mb-5 mt-5">INKOOP & VERKOOP</h2>
    <br><br>
    <h6>NEXT LEVEL is gespecialiseerd in de aankoop van
      tweedehandswagens – ongeacht het type of merk!
    </h6>

    <p class="mt-3">
      Overname bij aankoop nieuwe of tweedehandswagen
      Wenst u bij aankoop van een nieuwe of tweedehandswagen uw huidig
      voertuig van de hand te doen? We nemen graag deze zorg van u over: u
      kan vrijblijvend uw wagen aan ons overlaten voor een gunstige prijs.
    </p>

    <p class="mt-3">
      Wij kopen auto's! Als auto opkoper kopen we elke type wagen: 4X4-wagens en terreinwagens,
      camionetten en bestelwagens, schadewagens,auto's voor export, auto’s met verlopen keuring,
      wagens met véél kilometers, gezinswagens, sportwagens,...
    </p>

    <p class="mt-3">
      NEXT LEVEL is auto opkoper van alle automerken: Audi, BMW, Citroën, Chevrolet, Fiat, Ford,
      Honda, Jeep, Kia, Lancia, Mazda, Mercedes, Mitsubishi, Nissan, Opel, Peugeot, Porsche, Renault,
      Rover, Saab, Seat, Skoda, Suzuki, Toyota, Volkswagen, Volvo...
    </p>

    <p class="mt-3">
      Heeft u een auto te koop? Benieuwd naar de waarde van uw auto? Contacteer ons vrijblijvend. U
      zal er geen spijt van hebben
    </p>



  </div>

</body>
