<!DOCTYPE html>
<html>

<head>
  <title>Mobile First Responsive</title>
  <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no">

</head>

<body>
  <header class="hero-image">
    <section class="container-fluid">
      <div class="text-center">
        <h1 style="padding-top: 5em;" class="text-white">Chiptuning op maat voor uw auto</h1>
        <br>
        <button routerLink="/chiptuning" class="btn btn-zoeken">Zoeken</button>
      </div>
    </section>
  </header>


  <div style="margin-top: 4em;"></div>


  <section class="row container-fluid  m-0 " style="background-color:rgba(190, 182, 182, 0.329);">


    <div class="col text-center p-2 rounded">
      <img class="rounded" src="../../../assets/sec-2.jpg" height="350" width="400em" alt="">
    </div>

    <div class="col p-2 text-center">
      <h2>Chiptuning op maat
      </h2>
      <br>

      <h4>Meer rijplezier en besparen op brandstofkosten
      </h4>
      <p class="m-3">Moderne auto’s zijn voorzien van een computer (ECU), ook wel genaamd het
        regelapparaat. Dit regelapparaat is het brein van de motor. Het regelt
        bijvoorbeeld het ontstekingsmoment, de hoeveelheid ingespoten brandstof,
        inspuit duur, koppelbegrenzing, turbodruk etc. De computer is voorzien van
        een geheugen waar “parameters” met betrekking tot de regeling staan
        opgeslagen. Door middel van een software aanpassing is bij turbomotoren
        een vermogenswinst realiseerbaar van 20 tot 40%. Dit uit zich in een duidelijk
        merkbare koppeltoename (trekkracht) en een verhoging van de topsnelheid.
        Bij atmosferische motoren (zonder turbo) is de vermogenswinst een stuk
        lager, ongeveer 6 tot 8%.
      </p>

      <button routerLink="/contact" class="btn m-2 " style=" background-color: red; color: white; width: 50%;">Afspraak
        maken</button>

    </div>
  </section>


  <section class="row container-fluid  m-0 mt-5 " style="background-color:rgba(190, 182, 182, 0.329)">

    <div class="col p-2 text-center">
      <h2>Adviespunten na chiptuning

      </h2>
      <br>

      <h4 class="text-secondary">Optimaal genieten van uw auto

      </h4>
      <p class="m-3 text-secondary ">Om optimaal plezier te behouden van uw auto na de tuning, dient u de motor
        geleidelijk warm te rijden en na een lange reis goed af te laten koelen. Dit geldt
        natuurlijk ook voor originele auto’s. Het is erg belangrijk tijdig onderhoud en
        reparaties bij defecten uit te laten voeren. Uiteraard met gebruik van de juiste
        motorolie en onderdelen voorgeschreven door de fabrikant. Bij benzinemotoren
        raden wij aan benzine met een octaan gehalte van 98 te tanken. Alle
        verbrandingsmotoren presteren het best bij gebruik van Shell V-Power, Total
        Excellium of BP Ultimate. Het is raadzaam om de aanbevelingen volgen.
      </p>

      <button routerLink="/contact" class="btn m-2 " style=" background-color: red; color: white; width: 50%;">Afspraak
        maken</button>
    </div>

    <div class="col text-center p-2 rounded">
      <img class="rounded" src="../../../assets/sec-3.jpg" height="auto" width="400em" alt="">
    </div>

  </section>


  <section class="row container-fluid  m-0 mt-5 " style="background-color:rgba(190, 182, 182, 0.329)">


    <div class="col text-center p-2 rounded">
      <img class="rounded" src="../../../assets/sec-1.jpg" height="auto" width="400em" alt="">
    </div>

    <div class="col p-2 text-center">
      <h2>Chiptuning of powerbox?

      </h2>
      <br>

      <h4>Alle parameters zorgvuldig op elkaar afgestemd

      </h4>
      <p class="m-3">Jammer genoeg heeft chiptuning een minder goede naam, wat volledig
        onterecht is. Dit is voornamelijk te danken aan de zogenaamde tuningsboxen,
        powerboxen of ook wel genaamd bypass tuning. Dit zijn elektronische modules
        die tussen de bedrading naar het regelapparaat of sensoren toe geplaatst
        worden. Op deze wijze verandert er aan het totale motormanagement niets. Dit
        resulteert in niet goed op elkaar afgestemde parameters in het
        motormanagement en dus slechte onderlinge doseringsverhoudingen. Deze
        manier van tunen levert wel een vermogenswinst op, maar houdt geen
        daadwerkelijke optimalisatie van het motormanagement in. Onze ervaring leert
        dat deze manier van tunen op de lange termijn onnodige slijtage en schade aan
        de motor kan toebrengen.
      </p>

      <button routerLink="/contact" class="btn m-2 " style=" background-color: red; color: white; width: 50%;">Afspraak
        maken</button>

    </div>
  </section>


  <section class="row container-fluid  m-0  mt-5" style="background-color:rgba(190, 182, 182, 0.329)">



    <div class="col p-2 text-center">
      <h2>Waarom gebruikt de fabrikant<br> deze marge zelf niet?


      </h2>
      <br>

      <h4>optimalisatie voor u auto </h4>
      <p class="m-3">U zult zich wellicht afvragen waarom de fabrikant de software niet optimaal afstelt.
        De fabrikant bouwt auto’s welke in een groot aantal landen ingezet worden. De
        software wordt door de fabrikant afgesteld met een grote marge. In bepaalde
        landen is deze marge dan ook noodzakelijk vanwege allerlei factoren. Denk hier
        bijvoorbeeld aan extreme weersomstandigheden, slechte onderhoudsintervallen,
        lage kwaliteit brandstoffen en olie etc. In Nederland verkeren wij wat dat betreft in
        ideale omstandigheden en kan dus zonder gevaar voor de motor de optimalisatie
        plaatsvinden.
      </p>

      <button routerLink="/contact" class="btn m-2 " style=" background-color: red; color: white; width: 50%;">Afspraak
        maken</button>

    </div>


    <div class="col text-center p-2 rounded">
      <img class="rounded" src="../../../assets/sec-4.jpg" height="auto" width="400em" alt="">
    </div>
  </section>

  <section class="m-5">
    <div style="text-align: center;">
      <img class="mr-5 ml-5 mb-2" src="../../../assets/hard.jpg" width="100" height="100" alt="">
      <img class="mr-5 ml-5 mb-2" src="../../../assets/map.jpg" width="100" height="100" alt="">
      <img class="mr-5 ml-5 mb-2" src="../../../assets/airco.png" width="100" height="100" alt="">
    </div>
  </section>
</body>

</html>
