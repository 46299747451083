import { Component } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "nextLevel";
  navbarOpen = false;
  isVisible = true;
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }


  
}
