<!DOCTYPE html>
<html>

<head>
  <title>NextLevel</title>
  <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no">
<meta name="description" content="Chiptuning auto belgi� " />
<meta name="auto" content="Chiptuning op maat voor u auto" />
<meta name="chiptuning auto belgi�" content="Chiptuning antwerp, Chiptuning antwerpen, Chiptuning gent, Chiptuning autos, chiptuning meten" />
</head>

<body>
  <header class="hero-image pt-5 pb-5 pl-1 container-fluid mydiv">
    <div class="col-4 text-light  transparant-bg p-4 ">
      <h4>
        Ontdekken het krachtpotentieel van uw
        wagen met <p class="nextLevel-text">NEXT LEVEL!</p>
      </h4>
      <br>
      <h6>
        Een chiptuning op maat van NEXT LEVEL maakt het voor u
        waar! <br>NEXT LEVEL is gespecialiseerd in het optimaliseren van
        de motormanagementsoftware voor verbetering van de
        prestaties van benzine- en dieselvoertuigen.
      </h6>
      <br>
      <button routerLink="/chiptuning" class="btn btn-red">Vermogenswinst berekenen
      </button>
    </div>
  </header>

 <div id="small-screen" class="text-light    transparant-bg p-4 ">
    <h4>
      Ontdekken het krachtpotentieel van uw
      wagen met <p class="nextLevel-text">NEXT LEVEL!</p>
    </h4>
    <br>
    <h6>
      Een chiptuning op maat van NEXT LEVEL maakt het voor u
      waar! <br>NEXT LEVEL is gespecialiseerd in het optimaliseren van
      de motormanagementsoftware voor verbetering van de
      prestaties van benzine- en dieselvoertuigen.
    </h6>
    <br>
    <button routerLink="/chiptuning" class="btn btn-red">Vermogenswinst berekenen
    </button>
  </div>



  <section>
    <img class="w-100" src="../../../assets/sec.PNG">
  </section>
  <section class="container-fluid m-0 text-white text-center row bg-black">
    <div class="col">
    </div>
    <div class="col">
      <br><br>
      <h2 class="font-weight-bold">Chiptuning, op een veilige manier
        meer prestaties</h2>
      <br><br>
      <p>NEXT LEVEL is een begrip op het gebied van Chiptuning. Door onze
        unieke werkwijze hebben wij een breed klantenbestand opgebouwd, van
        merk dealers zoals Volkswagen, Audi, Seat, Skoda, BMW, Mercedes
        Dealers, kleine universele garages maar uiteraard onze grootste groep
        eindgebruikers</p>
      <button routerLink="/contact" class="btn m-2 btn-red w-75">Afspraak
        maken</button>
    </div>
    <div class="col"></div>
  </section>

  <section class="row container-fluid   m-0 mt-5  text-white smaller-screen">
    <div class="col background m-2 smaller-screen">
      <div class="div-w-70 mt-4 ml-2">
        <h4>CHIPTUNING VOOR MEER VERMOGEN EN SOUPLESSE</h4>
        <p class="div-w-70">
          Bereken nu je vermogenwinst voor je voortuig.
        </p>
      </div>
      <div class="row">
        <div class="col">
          <button routerLink="/chiptuning" class="btn btn-danger btn-red margin-top-10">Vermogenswinst berekenen
          </button>
        </div>
        <div class="col">
          <img src="../../../assets/home-sec-0-left.png" alt="home-sec-0-left">

        </div>
      </div>

    </div>


    <div class="col background m-2 smaller-screen">
      <div class="div-w-70 mt-4 ml-2">
        <h4>EEN ZUINIGE ECO TUNING OP MAAT DOOR NEXT LEVEL</h4>
        <p class="div-w-70">
          Zuiniger rijden zonder prestatie verlies. Boek een afspraak.
        </p>
      </div>
      <div class="row">
        <div class="col">
          <button routerLink="/contact" class="btn btn-red" style="margin-top: 9em; height: 4em;">Afspraak maken
          </button>
        </div>
        <div class="col">
          <img src="../../../assets/home-sec-0-right.png" alt="home-sec-0-right">

        </div>
      </div>

    </div>


  </section>

  <section class="row container-fluid  m-0 mt-5  border sec-bg ">
    <div class="col text-center p-2 rounded text-center">
      <img class="rounded" src="../../../assets/home-sec-1.jpg" height="auto" width="400em" alt="">
    </div>
    <div class="col p-2 text-center">
      <h2>Software op maat geschreven
      </h2>
      <br> <br>
      <p>Onze in-house ECU engineers zorgen ervoor dat je voertuig<br>
        zeer optimaal wordt geoptimaliseerd. Met onze uitgebreide<br>
        expertise kunnen we elke soort project aan. Van een simpele<br>
        op maat geschreven Stage 1 tot een volledig omgebouwd<br>
        project. Alles is mogelijk naar wens.</p>

      <button routerLink="/contact" class="btn m-2 btn-afspraak">Afspraak
        maken</button>

    </div>
  </section>
  <section class="row container-fluid  m-0  mt-5  sec-bg">

    <div class="col p-2 text-center">

      <p><b class="text-red">NEXT LEVEL</b> de specialist van<br>
        automobielonderhoud, biedt u talloze diensten in de<br>
        werkplaats om te waken over de goede functionering<br>
        van uw voertuig. Voor de olieverversing en het<br>
        onderhoud van uw auto, kunnen we verschillende<br>
        diensten uitvoeren: montage van de luchtfilter,<br>
        montage van de dieselfilter, vervanging van de<br>
        remvloeistof, montage van de remvoering en<br>
        remschijven voor of achter...
      </p>

      <button routerLink="/contact" class="btn m-2 btn-afspraak ">Afspraak
        maken</button>

    </div>

    <div class="col text-center p-2 rounded">
      <img class="rounded" src="../../../assets/home-sec-2.jpg" height="auto" width="400em" alt="home-sec-2">
    </div>
  </section>


  <section class="row container-fluid  m-0 mt-5 border sec-bg">
    <div class="col text-center p-2 rounded text-center">
      <img class="rounded" src="../../../assets/home-sec-3.jpg" height="auto" width="400em" alt="home-sec-3">
    </div>
    <div class="col p-2 text-center">
      <h2>Ontdek de expertise van NEXT LEVEL
      </h2>
      <br> <br>
      <p>Bij <b class="text-red">NEXT LEVEL</b> kan je terecht voor allerlei performance<br>
        upgrades. Van een op maat geschreven software tot een<br>
        complete engine re-build. Wij bouwen en programmeren<br>
        alles in-house op maat.</p>

      <button routerLink="/contact" class="btn m-2 btn-afspraak">Afspraak
        maken</button>

    </div>
  </section>
</body>

</html>
