<!-- Load Facebook SDK for JavaScript -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<div id="fb-root"></div>
<script>
  window.fbAsyncInit = function () {
    FB.init({
      xfbml: true,
      version: 'v9.0'
    });
  };

  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/nl_NL/sdk/xfbml.customerchat.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

</script>

<!-- Your Chat Plugin code -->
<div class="fb-customerchat" attribution=setup_tool page_id="102389008525385">
</div>

<hr class="border-page-top" />

<nav class="navbar navbar-expand-lg navbar-light bg-light page-top-margin">
  <a class="navbar-brand" href="#">
    <img src="../assets/nextlevel-logo.png" height="70" width="150">

  </a>

  <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>


  <div class="collapse navbar-collapse justify-content-end" [ngClass]="{ 'show': navbarOpen }">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a routerLink="/home" class="nav-link ">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/chiptuning">Vermogenswinst berekenen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/vermogensmeting">Chiptuning</a>
      </li>
      <li class="nav-item">
        <a routerLink="/kopen-verkopen" class="nav-link">Inkopen & verkopen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/bedrijven">Over ons</a>
      </li>
      <li class="nav-item">
        <a routerLink="/contact" class="nav-link">Afspraak boeken</a>
      </li>
    </ul>
  </div>
</nav>
<!-- <nz-modal
[(nzVisible)]="isVisible"
nzTitle="Modal Title"
(nzOnCancel)="handleCancel()"
(nzOnOk)="handleOk()"
[nzOkLoading]="isOkLoading"
>
<p *nzModalContent>Modal Content</p>
</nz-modal> -->
<router-outlet></router-outlet>


<footer class="container-fluid">
  <div class="row bg-secondary text-white  ">
    <div class="col m-5">
      <h4>OVER NEXT LEVEL </h4>
      <br>
      <p>Begin 2017 zijn we next level gestart als officieel bedrijf om onze
        horizonen te verbreden. Een belangrijke stap in de richting van
        onze toekomstplannen. Pas in 2019 zijn we een van de meest
        populaire chiptuning-bedrijven in België geworden.
        Kom meer te weten</p>
    </div>

    <div class="col m-5 ">

      <table class="table table-striped table-hover ">
        <tr>
          <td> Maandag </td>
          <td>09.00 - 21.00 U</td>
        </tr>
        <tr>
          <td> Dinsdag </td>
          <td>09.00 - 21.00 U</td>
        </tr>
        <tr>
          <td> Woensdag </td>
          <td>09.00 - 21.00 U</td>
        </tr>
        <td> Donderdag </td>
        <td>09.00 - 21.00 U</td>
        <tr>
          <td> Vrijdag </td>
          <td>09.00 - 21.00 U</td>
        </tr>
        <td> Zaterdag </td>
        <td>09.00 - 21.00 U</td>
        <tr>
          <td> Zondag </td>
          <td>09.00 - 21.00 U</td>
        </tr>

      </table>


    </div>

    <div class="col m-5 text-white">
      <h3>Contact informatie</h3>
      <br><br>
      <p>Hoofdkantoor & werkruimte:<br>
        Van Duyststraat 137, 2100 Deurne </p>
      <br><br>
      <p>Telefoonnummer: +32 (0)4 85 14 74 35<br>
        Adem.murat@icloud.com
      </p>
      <br>
      <ul>
        <li>
          <a class="a-white" href="https://www.facebook.com/Next-Level-102389008525385"><i
              class="fa fa-facebook"></i></a>
        </li>
        <li>
          <a class="a-white" href="https://www.instagram.com/adem.murat.1"><i class="fa fa-instagram"></i></a>
        </li>

      </ul>
    </div>

    <div>

    </div>
  </div>
</footer>
