import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-chiptuning",
  templateUrl: "./chiptuning.component.html",
  styleUrls: ["./chiptuning.component.scss"],
})
export class ChiptuningComponent implements OnInit {
  loading: boolean = false;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = true;
    }, 100);
  }
}
