import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./components/home/home.component";
import { ContactComponent } from "./components/contact/contact.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { VermogensmetingComponent } from "./components/vermogensmeting/vermogensmeting.component";
import { BedrijvenComponent } from "./components/bedrijven/bedrijven.component";
import { ChiptuningComponent } from "./components/chiptuning/chiptuning.component";
import { KopenVerkopenComponent } from "./components/kopen-verkopen/kopen-verkopen.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    VermogensmetingComponent,
    BedrijvenComponent,
    ChiptuningComponent,
    KopenVerkopenComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
