import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  FormData: FormGroup;
  email: any;

  ngOnInit() {
    this.FormData = this.builder.group({
      fullname: new FormControl("", [Validators.required]),
      telefoonnummer: new FormControl("", [Validators.required]),
      merk: new FormControl("", [Validators.required]),
      modelenjaar: new FormControl("", [Validators.required]),
      huidigeVermogen: new FormControl("", [Validators.required]),
      brandstofType: new FormControl("", [Validators.required]),
      email: new FormControl("", [
        Validators.compose([Validators.required, Validators.email]),
      ]),
      comment: new FormControl("", [Validators.required]),
    });
  }
  constructor(private builder: FormBuilder) {}

  onSubmit(FormData) {
    console.log(this.FormData.controls["email"].value);
    for (const i in this.FormData.controls) {
      this.FormData.controls[i].markAsDirty();
      this.FormData.controls[i].updateValueAndValidity();
    }
    var formattedBody = `
    ${this.FormData.controls["comment"].value}
    \n
     -- Vrager gegevens --
     Name en voornaam: ${this.FormData.controls["fullname"].value} 
     Email: ${this.FormData.controls["email"].value}
     Telefoonnummer: ${this.FormData.controls["telefoonnummer"].value}
     \n
     -- Auto gegevens --
     Auto merk: ${this.FormData.controls["merk"].value}
     Auto modeljaar: ${this.FormData.controls["modelenjaar"].value}
     Huidige vermogen: ${this.FormData.controls["huidigeVermogen"].value}
     BrandstofType: ${this.FormData.controls["brandstofType"].value}\n\n`;

    location.href =
      "mailto:" +
      "Adem.murat@icloud.com" +
      "?cc=" +"Next.level.adem@gmail.com" +
      "&subject=" +
      "Next level vraag" +
      "&body=" +
      encodeURIComponent(formattedBody);
  }
}
