import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kopen-verkopen',
  templateUrl: './kopen-verkopen.component.html',
  styleUrls: ['./kopen-verkopen.component.scss']
})
export class KopenVerkopenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
