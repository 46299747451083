import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-vermogensmeting",
  templateUrl: "./vermogensmeting.component.html",
  styleUrls: ["./vermogensmeting.component.scss"],
})
export class VermogensmetingComponent implements OnInit {
  loading: boolean = false;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = true;
    }, 100);
  }
}
