<!DOCTYPE html>
<html>

<head>
  <title>Mobile First Responsive</title>
  <meta na  me="viewport" content="width=device-width, initial-scale=1, user-scalable=no">

</head>

<body>


  <div class="container">
    <div class="row container">
      <div class="col mt-4 mb-5">
        <div class="container">
          <form method="post" [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">

            <div class="form-group">
              <label for="fullname">Naam en voornaam </label>
              <input type="text" class="form-control" name="fullname" formControlName="fullname">
            </div>

            <div class="form-group">
              <label for="telefoonnummer">Telefoonnummer </label>
              <input type="tel" class="form-control" name="telefoonnummer" formControlName="telefoonnummer">
            </div>

            <div class="form-group">
              <label for="email">E-mail adres
              </label>
              <input type="email" class="form-control" name="email" aria-describedby="emailHelp"
                formControlName="email">
              <small id="emailHelp" class="form-text text-muted">Wij zullen uw email nooit delen met anderen!</small>
            </div>

            <div class="form-group">
              <label for="merk">Merk</label>
              <input type="text" class="form-control" name="merk" formControlName="merk">
            </div>

            <div class="form-group">
              <label for="modelenjaar">Model & Bouwjaar
              </label>
              <input type="text" class="form-control" name="modelenjaar" formControlName="modelenjaar">
            </div>
            <div class="form-group">
              <label for="huidigeVermogen">Huidige vermogen
              </label>
              <input type="text" class="form-control" name="huidigeVermogen" formControlName="huidigeVermogen">
            </div>
            <div class="form-group">
              <label for="brandstofType">Brandstoftype:</label>
              <select class="form-control" id="sel1" name="brandstofType" formControlName="brandstofType">
                <option value="" selected disabled hidden>Kies hier uw brandstoftype</option>
                <option>BENZINE</option>
                <option>DIESEL</option>
                <option>HYBRIDE</option>
                <option>ELEKTRISCH</option>
              </select>
            </div>

            <div class="form-group">
              <label for="comment">Uw vraag</label>
              <textarea class="form-control" formControlName="comment" name="comment">
                </textarea>
            </div>
            <button type="submit" class="btn btn-red" 
              [disabled]="!FormData.valid">Aanvragen</button>
          </form>


        </div>
      </div>

      <div class="col mt-4 ">
        <h1 class="text-center">Contact opnemen/afspraak boeken
        </h1>
        <p>
          Heeft u vragen over de diensten en producten van Next level of wenst u een
          vrijblijvende chiptuning offerte te ontvangen? Vult u dan het onderstaande
          formulier in. Na ontvangst van uw gegevens nemen wij zo spoedig mogelijk
          contact met u op. Geef op het formulier aan of uw voorkeur uitgaat naar de
          Belgische of Nederlandse vestiging.
        </p>
        <img src="../../assets/contact-info-img.PNG" height="auto" width="400em">
      </div>
    </div>
  </div>
</body>

</html>
