<!DOCTYPE html>
<html>

<head>
  <title>Mobile First Responsive</title>
  <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no">

</head>

<body>
  <header class="hero-image">
  </header>
  <div class="container">

    <div class="row">
      <div class="col m-5">
        <h2>
          Onderhoud & herstellingen
        </h2>
        <p>
          U kunt bij ons terecht voor alle onderhoud en herstellingen. Wij
          beschikken over de modernste apparatuur om uw wagen in optimale
          conditie te houden en hebben steeds een groot aantal onderdelen en
          wisselstukken in voorraad. Bij elk onderhoud controleren we uw wagen
          uitvoerig op eventuele gebreken en in samenspraak met u bekijken we
          wat al dan niet vervangen moet worden. We maken er een punt van om steeds te zoeken naar de beste en
          goedkoopste oplossing voor elk
          probleem!
        </p>
      </div>

      <div class="col m-5">
        <h2>
          Plaatsen trekhaak of parkeersensoren
        </h2>
        <p>
          Heeft u een trekhaak nodig of vindt u parkeren zonder parkeersensoren
          niet zo eenvoudig? Afhankelijk van uw budget en wensen maken wij een
          gepast voorstel. In no time plaatsen wij de trekhaak of parkeersensoren
          op uw wagen. U zult versteld staan dat dit helemaal niet duur hoeft te
          zijn!
        </p>
      </div>
    </div>




    <div class="row">
      <div class="col m-5">
        <h2>
          Airco onderhoud

        </h2>
        <p>
          Voor een optimale werking is het belangrijk het aircosysteem van uw
          wagen goed te laten onderhouden. Wij zorgen ervoor dat uw climatisatie perfect blijft werken door desinfectie
          van de leidingen en het vervangen/
          aanvullen van koelgas.
        </p>
      </div>

      <div class="col m-5">
        <h2>
          Bandenservice </h2>
        <p>
          Dankzij onze grote voorraad banden en winterbanden kunt u bij ons
          steeds de geschikte banden vinden voor uw wagen, overeenstemmend
          met uw rijgedrag en budget. De juiste keuze van kwalitatieve banden is
          namelijk zeer belangrijk en kwaliteit hoeft niet altijd duur te zijn! Bij de
          wissel van zomer- en winterbanden kunt u steeds uw banden gratis bij
          ons laten stockeren.
        </p>
      </div>
    </div>

  </div>

</body>
